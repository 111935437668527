body {
  background: #003bb8;
  background-image: linear-gradient(120deg,#003bb8,#269edb);
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: hidden;
}

span {
  a {
    text-decoration: none;
    color: inherit;
  }
}

li {
  a {
    text-decoration: none;
    color: inherit;
  }
}
