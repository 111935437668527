.profileImage {
  position: relative;
  right: -60px;
  width: 900px;
  height: 840px;
  display: grid;
  place-items: baseline end;
}

@keyframes twiddle {
  from{
    transform: rotateZ(-2deg);
  }

  to{
    transform: rotateZ(2deg);
  }
}

.introImage {
  z-index: 3;
  max-width: 100%;
  max-width: 500px;
  height: auto;
  border: 6px solid #f7e9e9;
  border-radius: 50%;
  filter: drop-shadow(0 0 20px rgba(7,8,18,.4));
  transform-origin: center;
  animation: twiddle 5s linear infinite alternate;
}

@mixin ringCircle {
  position: absolute;
  width: 500px;
  height: 500px;
  transform-origin: center;
  border: 2px solid rgba(19,20,33,.5);
  border-radius: 50%;
}

@keyframes planets {
  from{
    transform: rotateZ(0deg);
  }

  to{
    transform: rotateZ(-360deg);
  }
}

@mixin planetCircleBasic {
  z-index: 2;
  transform-origin: center;
  border-radius: 50%;
  box-shadow: 0 0 10px 1px rgba(43,43,43,.3);
  animation: planets 10s linear infinite;
}

@keyframes wheel{
  from{
    transform: scale(1.3) rotateZ(0);
  }

  to{
    transform: scale(1.3) rotateZ(360deg);
  }
}

.innerCircle {
  @include ringCircle;
  transform: scale(1.3);
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  animation: wheel 10s linear infinite;
  .planetCircle {
    @include planetCircleBasic;
    width: 46px;
    height: 46px;
    &:first-child {
      margin-left: -23px;
    }
    &:nth-child(2) {
      margin-right: -23px;
    }
  }
}

@keyframes wheel2{
  from{
    transform: scale(1.8) rotateZ(0);
  }

  to{
    transform: scale(1.8) rotateZ(360deg);
  }
}

.outerCircle {
  @include ringCircle;
  transform: scale(1.8);
  z-index: 1;
  border-width: 1px;
  animation: wheel2 20s linear infinite;
  .planetCircle {
    @include planetCircleBasic;
    position: absolute;
    animation-duration: 20s;
    width: 33px;
    height: 33px;
    &:first-child {
      top: 50%;
      left: 0;
      margin-left: -16.5px;
    }
    &:nth-child(2) {
      top: 0;
      left: 25%;
      margin-left: 16.5px;
    }
    &:nth-child(3) {
      top: 0;
      right: 25%;
      margin-right: 16.5px;
    }
    &:nth-child(4) {
      top: 50%;
      right: 0;
      margin-right: -16.5px;
    }
    &:nth-child(5) {
      bottom: 0;
      right: 25%;
      margin-right: 16.5px;
    }
    &:nth-child(6) {
      bottom: 0;
      left: 25%;
      margin-left: 16.5px;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .profileImage {
    transform-origin: center;
    height: 650px;
    --winWidth: 0.55;
    transform: scale(var(--winWidth));
    right: 0;
    place-items: baseline center;
  }
}