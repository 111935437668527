.header {
  width: 100%;
  height: 80px;
  z-index: 4;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  padding: 0 150px;
  height: 100%;
}

.logo {
  display: flex;
  align-items: center;
  height: 100%;
}

.headNav {
  z-index: 3;
  display: flex;
  align-items: center;
  height: 100%;
}

.logo {
  span {
    height: 100%;
    font-weight: 800;
    font-size: 1.4em;
    height: auto;
    color: #f9f4f4;
  }
}

.navList {
  display: flex;
  justify-content: space-between;
  span {
    font-weight: bold;
    font-size: 1.2em;
    margin: auto 20px;
    color: #f9f4f4;
  }
}

.sideBar {
  display: none;
  cursor: pointer;
  color: #fefefe;
}

.svgIcon {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
}

@mixin sideMenu {
  position: fixed;
  top: 16px;
  right: 25px;
  color: #fefefe;
  font-weight: 500;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  ul {
    padding: 0 16px;
  }
  li {
    list-style: none;
    padding: 6px 0;
  }
}

.sideMenuHide, .sideMenuShow {
  opacity: 0;
}

@media only screen and (max-width: 1024px) {
  .headerContainer {
    padding: 0 30px;
  }

  .navList {
    display: none;
  }

  .sideBar {
    display: block;
  }

  .sideMenuHide {
    @include sideMenu;
    z-index: -10;
    opacity: 0;
    transform: scale(0.75, 0.5625);
    transition-property: opacity, transform;
    transition-duration: 267ms, 178ms;
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  .sideMenuShow {
    @include sideMenu;
    z-index: 10;
    opacity: 1;
    transform: none;
    transition-property: opacity, transform;
    transition-duration: 267ms, 178ms;
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  }
}

.home {
  width: 100%;
}

.intro {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 50px 0;
}

@media only screen and (max-width: 1024px) {
  .intro {
    flex-direction: column-reverse;
    justify-content: unset;
    padding-top: 0;
    align-items: center;
    vertical-align: middle;
  }
}

.footer {
  width: 100%;
  background: #1b1b1b;
  color: #fefefe;
}

.footContent {
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.socialLinks {
  width: 30%;
  display: flex;
  justify-content: space-around;
  font-size: 1.3rem;
  align-items: center;
}

@media only screen and (max-width: 1024px) {
  .footContent {
    flex-direction: column-reverse;
  }

  .socialLinks {
    width: 100%;
  }

  .copyrightText {
    font-size: 1rem;
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid rgba(121, 121, 121, 0.7);
  }
}